import React from 'react';
import FullscreenToast from './FullscreenToast';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import Box from '@material-ui/core/Box';
import './App.css';

const col1 = 74;
const col2 = 166;
const col3 = 258;

const rowSpaceTop = (row) => 31 + 69.2 * row;

const elements = [
  { value: -10, top: rowSpaceTop(4), left: col1 },
  { value: -9, top: rowSpaceTop(3), left: col1 },
  { value: -8, top: rowSpaceTop(2), left: col1 },
  { value: -7, top: rowSpaceTop(1), left: col1 },
  { value: -6, top: rowSpaceTop(0), left: col1 },
  { value: -5, top: rowSpaceTop(0), left: col2 },
  { value: -4, top: rowSpaceTop(1), left: col2 },
  { value: -3, top: rowSpaceTop(2), left: col2 },
  { value: -2, top: rowSpaceTop(3), left: col2 },
  { value: -1, top: rowSpaceTop(4), left: col2 },
  { value: 0, top: rowSpaceTop(5), left: col2 },
  { value: 1, top: rowSpaceTop(6), left: col2 },
  { value: 2, top: rowSpaceTop(7), left: col2 },
  { value: 3, top: rowSpaceTop(8), left: col2 },
  { value: 4, top: rowSpaceTop(9), left: col2 },
  { value: 5, top: rowSpaceTop(10), left: col2 },
  { value: 6, top: rowSpaceTop(10), left: col3 },
  { value: 7, top: rowSpaceTop(9), left: col3 },
  { value: 8, top: rowSpaceTop(8), left: col3 },
  { value: 9, top: rowSpaceTop(7), left: col3 },
  { value: 10, top: rowSpaceTop(6), left: col3 }
];

function App() {
  const [state, setState] = React.useState(0);

  const counter = elements.find((elem) => elem.value === state);

  let rotation = 0;
  if (state > 0) {
    rotation = 270;
  } else if (state < 0) {
    rotation = 90;
  }

  return (
    <div className="App">
      <header className="App-header">
        <Grid
          container
          direction="row"
          justifyContent="center"
          alignItems="center"
          sx={{
            height: '100vh',
            background: 'url(/bg-backup.png) no-repeat center center fixed',
            backgroundSize: 'cover'
          }}
        >
          {elements.map((element) => (
            <Button
              sx={{
                position: 'fixed',
                borderRadius: 10,
                top: element.top,
                left: element.left
              }}
              onClick={() => setState(element.value)}
            >
              <Box sx={{ height: '44px' }} />
            </Button>
          ))}
          <Box
            sx={{
              transform: `rotate(${rotation}deg)`,
              backgroundColor: 'white',
              position: 'fixed',
              borderRadius: 10,
              top: counter.top,
              left: counter.left
            }}
          >
            <img src="/53267.png" />
          </Box>
        </Grid>
      </header>
    </div>
  );
}

export default App;
